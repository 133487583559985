<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle>{{ $t('labels.firm') }}</v-list-item-subtitle>
      <v-list-item-title v-if="firm">{{ firm.display }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'VFirmListItem',
  props: {
    firmId: { type: [Number, String], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['firms']),
    firm() {
      return this.firms.find((x) => x.id === this.firmId)
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listFirms'])
  },
  created() {
    this.listFirms()
  }
}
</script>

<i18n>
{
  "en": {
    "displayName": "@:labels.displayName"
  },
  "en": {
    "displayName": "@:labels.displayName"
  }
}
</i18n>
