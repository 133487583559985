<template>
  <VModalWidget
    :dismiss="dismiss"
    :submit="doSubmit"
    :loading="loading"
    :submitting="working"
    :submitTitle="$t('save')"
  >
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          v-model.trim="form.firstname"
          :label="$t('firstname')"
          :error-messages="firstnameErrors"
          required
          @input="$v.form.firstname.$touch()"
          @blur="$v.form.firstname.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <v-text-field
          v-model.trim="form.lastname"
          :label="$t('lastname')"
          :error-messages="lastnameErrors"
          required
          @input="$v.form.lastname.$touch()"
          @blur="$v.form.lastname.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <v-text-field
          v-model.trim="form.display"
          :label="$t('displayName')"
          :error-messages="displayErrors"
          required
          @input="$v.form.display.$touch()"
          @blur="$v.form.display.$touch()"
          v-on:keyup.enter="doSubmit"
        />
        <template v-if="isNew">
          <v-text-field
            v-model.trim="form.login"
            :error-messages="loginErrors"
            v-bind:label="$t('labels.login')"
            required
            @input="$v.form.login.$touch()"
            @blur="$v.form.login.$touch()"
          />
          <v-text-field
            v-model.trim="form.email"
            :error-messages="emailErrors"
            v-bind:label="$t('labels.email')"
            required
            @input="$v.form.email.$touch()"
            @blur="$v.form.email.$touch()"
          />
          <v-text-field
            v-model.trim="form.password"
            :error-messages="passwordErrors"
            v-bind:label="$t('labels.password')"
            type="password"
            required
            @input="$v.form.password.$touch()"
            @blur="$v.form.password.$touch()"
          />
        </template>
        <v-text-field
          v-model.trim="form.provider_broker_id"
          :label="$t('brokerId')"
          v-on:keyup.enter="doSubmit"
          clearable
        />
        <FirmField v-model="form.firm_id" :errors="firmIdErrors" />
        <AgencyField v-model="form.meta.agency" :firm-id="form.firm_id" />
        <CommissionField v-model.trim="form.meta.commissioning" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { mapActions } from 'vuex'
import { BaseForm, FormErrors, VModalWidget } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { required, email } from 'vuelidate/lib/validators'
import { NAMESPACE } from '@argon/iam/users/store'
import { FirmField, AgencyField } from '@/firms/components'
import { CommissionField } from '@/components'

export default {
  name: 'ModalProfile',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    FormErrors,
    VModalWidget,
    FirmField,
    AgencyField,
    CommissionField
  },
  props: {
    userId: { type: [Number, String], required: true }
  },
  validations() {
    return this.isNew
      ? {
          form: {
            display: { required },
            firstname: { required },
            lastname: { required },
            login: { required },
            password: { required },
            email: { required, email },
            firm_id: {},
            provider_broker_id: {}
          }
        }
      : {
          form: {
            display: { required },
            firstname: { required },
            lastname: { required },
            firm_id: {},
            provider_broker_id: {}
          }
        }
  },
  data: () => ({
    returnPath: { name: 'organizationSettings' },
    form: { meta: {} },
    loading: true,
    working: false
  }),
  computed: {
    isNew() {
      return this.userId === 'new'
    },
    isIA() {
      return this.form.firm_id === 10
    },
    displayErrors() {
      const errors = []
      if (!this.$v.form.display.$dirty) return errors
      !this.$v.form.display.required && errors.push(this.$t('required'))
      this.getServerErrors('display', errors)
      return errors
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('required'))
      return errors
    },
    lastnameErrors() {
      const errors = []
      this.getServerErrors('lastname', errors)
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('required'))
      return errors
    },
    loginErrors() {
      const errors = []
      if (!this.$v.form.login.$dirty) return errors
      !this.$v.form.login.required && errors.push(this.$t('required'))
      this.getServerErrors('login', errors)
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('required'))
      this.getServerErrors('password', errors)
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required && errors.push(this.$t('required'))
      !this.$v.form.email.email && errors.push(this.$t('invalidEmail'))
      this.getServerErrors('email', errors)
      return errors
    },
    firmIdErrors() {
      const errors = []
      if (!this.$v.form.firm_id.$dirty) return errors
      this.getServerErrors('firm_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getUser', 'patchUser', 'createUser']),
    doSubmit() {
      this.resetServerErrors()
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      const callback = this.isNew ? this.createUser : this.patchUser
      this.working = true
      const payload = this.isNew ? this.form : { userId: this.userId, data: this.form }
      callback(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    }
  },
  created() {
    if (this.userId !== 'new') {
      this.getUser(this.userId).then((user) => {
        this.profile = user
        this.form = pick(user, ['firstname', 'lastname', 'display', 'firm_id', 'provider_broker_id', 'meta'])
      })
    }
    this.loading = false
  }
}
</script>

<i18n>
{
  "en": {
    "brokerId": "Broker ID",
    "displayName": "@:labels.displayName",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.profile"
  },
  "fr": {
    "brokerId": "ID Courtier",
    "displayName": "@:labels.displayName",
    "firstname": "@:labels.firstname",
    "lastname": "@:labels.lastname",
    "required": "@:labels.required",
    "save": "@:labels.save",
    "title": "@:labels.profile"
  }
}
</i18n>
