<template>
  <v-select
    v-model="localValue"
    :items="agencies"
    item-text="display"
    item-value="agency_code"
    hide-no-data
    :label="$t('labels.agency')"
    :disabled="isDisabled"
    required
    :error-messages="errors"
    clearable
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'AgencyField',
  mixins: [CustomFieldMixin],
  props: {
    firmId: { type: Number }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getReadonOnlyFirmAgencies'])
  },
  computed: {
    ...mapState(NAMESPACE, ['agencies']),
    isDisabled() {
      return !this.firmId || this.agencies.length === 0
    }
  },
  watch: {
    firmId: function (newVal, oldVal) {
      if (oldVal) {
        this.setValue(null)
      }
      this.getReadonOnlyFirmAgencies(newVal).catch(() => {})
    }
  },
  created() {
    this.getReadonOnlyFirmAgencies(this.firmId).catch(() => {})
  }
}
</script>
