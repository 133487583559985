<template>
  <v-autocomplete
    v-model="localValue"
    :items="firms"
    item-text="display"
    :item-value="itemValue"
    hide-no-data
    :label="$t('labels.firm')"
    required
    :error-messages="errors"
    clearable
  />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CustomFieldMixin } from '@argon/app/mixins'
import { NAMESPACE } from '../store'
export default {
  name: 'FirmField',
  mixins: [CustomFieldMixin],
  props: {
    itemValue: { type: String, default: 'id' }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listReadOnlyFirms'])
  },
  computed: {
    ...mapState(NAMESPACE, ['firms'])
  },
  created() {
    this.listReadOnlyFirms()
  }
}
</script>
