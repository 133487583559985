<template>
  <v-card outlined>
    <v-data-table :headers="headers" :items="agencies" :loading="loading" class="main-table elevation-0">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="editAgency(item.id)" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.view') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'FirmAgencies',
  data() {
    return {
      loading: true,
      headers: [
        { text: this.$t('labels.displayName'), value: 'display', align: 'left' },
        { text: this.$t('labels.agencyCode'), value: 'agency_code', align: 'left', width: 200 },
        { text: '', value: 'actions', sortable: false, align: 'right', width: 20 }
      ]
    }
  },
  props: {
    firmId: { type: [Number, String], required: true }
  },
  computed: {
    ...mapState(NAMESPACE, ['agencies'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getFirmAgencies', 'clearFirmAgencies']),
    editAgency(agencyId) {
      this.$router.push({ name: 'editAgency', params: { agencyId } })
    }
  },
  created() {
    return this.getFirmAgencies(this.firmId).then(() => {
      this.loading = false
    })
  },
  destroyed() {
    this.clearFirmAgencies()
  }
}
</script>

<style></style>
